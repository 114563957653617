import g from '../../images/gallery1.png'
import g1 from '../../images/gallery2.png'
import g2 from '../../images/g3.png'
import g3 from '../../images/g4.png'
import g4 from '../../images/g5.png'
import g5 from '../../images/g6.png'
import g6 from '../../images/g7.png'
import g8 from '../../images/g9.png'
import g10 from '../../images/g11.png'
import g11 from '../../images/g12.png'
import g12 from '../../images/g13.png'
import g13 from '../../images/g14.png'
import g14 from '../../images/g15.png'
import g15 from '../../images/g16.png'
import g16 from '../../images/g17.png'
import g18 from '../../images/g19.png'
import g20 from '../../images/g21.png'
import g24 from '../../images/g25.png'
import g25 from '../../images/g26.png'
import g28 from '../../images/g29.png'
import g29 from '../../images/g30.png'
import g30 from '../../images/g31.png'
import g31 from '../../images/g32-min.png'
import b from '../../images/b.png'
import b2 from '../../images/b2.png'
import b3 from '../../images/b3.png'
import b5 from '../../images/b5.png'
import b6 from '../../images/b6.png'
import b7 from '../../images/b7.png'
import b8 from '../../images/b8.png'
import b9 from '../../images/b9.png'
import b10 from '../../images/b10.png'
import b11 from '../../images/b11.png'
import b12 from '../../images/b12.png'

export const gallery = [
    g,
    g1,
    g18,
    g2,
    g3,
    g4,
    g5,
    g6,
    // g7,
    g8,
    // g9,
    g10,
    g11,
    g12,
    g13,
    g14,
    g15,
    g16,
    // g17,
    // g19,
    g20,
    // g21,
    // g22,
    // g23,
    g24,
    g25,
    // g26,
    // g27,
    g28,
    g29,
    g30,
    g31,
    b,
    // b1,
    b2,
    b3,
    // b4,
    b5,
    b6,
    b7,
    b8,
    b9,
    b10,
    b11,
    b12
    
]
